<template>
  <v-card class="mx-auto" max-width="500">
    <v-card-title class="title font-weight-regular justify-center">
      <span>{{ $t(model.title) }}</span>
    </v-card-title>

    <v-card-title class="title font-weight-regular justify-space-between">
      <span>{{ $t(currntModel.title) }}</span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="step + 1"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="step">
      <v-window-item v-for="(el, i) in model.data" :key="i" :value="i">
        <v-card-text>
          <a-form-from-model v-model="data" :model="el"></a-form-from-model>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
        <v-card-text>
          <v-text-field label="Password" type="password"></v-text-field>
          <v-text-field label="Confirm Password" type="password"></v-text-field>
          <span class="caption grey--text text--darken-1">
            Please enter a password for your account
          </span>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="3">
        <div class="pa-4 text-center">
          <v-img
            class="mb-4"
            contain
            height="128"
            src="https://cdn.vuetifyjs.com/images/logos/v.svg"
          ></v-img>
          <h3 class="title font-weight-light mb-2">Welcome to Vuetify</h3>
          <span class="caption grey--text">Thanks for signing up!</span>
        </div>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn :disabled="step === 1" text @click="step--"> Back </v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="step === 3" color="primary" depressed @click="step++">
        Next
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const model = {
  title: "Sing up",
  data: [
    {
      title: "Personal Information",
      data: [
        {
          name: "name",
          title: "Name",
          validator: ["req"],
          type: "string",
        },
        {
          name: "surname",
          title: "surname",
          validator: ["req"],
          type: "string",
        },
        {
          name: "middlename",
          title: "middlename",
          type: "string",
        },
        {
          name: "photo",
          title: "photo",
          type: "string",
        },
        {
          name: "birthday",
          title: "birthday",
          type: "string",
        },
        {
          name: "country",
          title: "country",
          validator: ["req"],
          type: "string",
        },
        {
          name: "lang",
          title: "lang",
          validator: ["req"],
          type: "string",
        },
      ],
    },
  ],
};
export default {
  data() {
    return {
      data: {},
      model: model,
      step: 0,
    };
  },

  computed: {
    currntModel() {
      return this.model.data[this.step];
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Sign-up";
        case 2:
          return "Create a password";
        default:
          return "Account created";
      }
    },
  },
};
</script>